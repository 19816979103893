import React from 'react';

const cleanPercentage = (percentage) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ colour, percentage, size }) => {
  const r = size / 4; // Adjusted radius based on size prop
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      r={r}
      cx={size / 2} // Adjusted center based on size prop
      cy={size / 2} // Adjusted center based on size prop
      fill={"transparent"}
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={"3"} // Adjusted stroke width for better visibility
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    ></circle>
  );
};

const Text = ({ percentage, size }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={`${size / 8}px`} // Adjusted font size based on size prop
    >
      {percentage}
    </text>
  );
};

const PieChart = ({ percentage, size }) => {
  let colour = '';
  if(percentage <= 25) {
    colour = '#dadada'
  } else if(percentage > 25 && percentage <= 50) {
    colour = '#ffd482';
  } else if(percentage > 50 && percentage <= 75) {
    colour = '#17dfdf';
  } else if(percentage > 75 && percentage <= 100) {
    colour = '#0068B5';
  }
  const pct = percentage;
  return (
    <svg width={size} height={size}> {/* Adjusted width and height based on size prop */}
      <g transform={`rotate(-90 ${size / 2} ${size / 2})`}> {/* Adjusted center based on size prop */}
        <Circle colour="#f6f5f4" size={size} />
        <Circle colour={colour} percentage={pct} size={size} />
      </g>
      <Text percentage={pct} size={size} />
    </svg>
  );
};

export default PieChart;

import React, { useState, useEffect, useRef } from 'react';
import './chanel-audience-ddl.styles.scss';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TechIcon from '@mui/icons-material/Computer';
import GameIcon from '@mui/icons-material/SportsEsports';
import CreatorIcon from '@mui/icons-material/EditNote';
import DataIcon from '@mui/icons-material/TextSnippet';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ChannelAudienceDropdown = ({ onSelectChannel, onSelectAudience }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [channel, setChannel] = useState('');
    const [audience, setAudience] = useState('');
    const dropdownRef = useRef(null);

    const dropdownItems = [
        { channelIcon: <InstagramIcon />, audienceIcon: <TechIcon />, channel: 'Instagram', audience: 'Tech Enthusiasts' },
        { channelIcon: <InstagramIcon />, audienceIcon: <GameIcon />, channel: 'Instagram', audience: 'Gamers' },
        { channelIcon: <InstagramIcon />, audienceIcon: <CreatorIcon />, channel: 'Instagram', audience: 'Professional Creators' },
        { channelIcon: <LinkedInIcon />, audienceIcon: <TechIcon />, channel: 'LinkedIn', audience: 'Tech Enthusiasts' },
        { channelIcon: <LinkedInIcon />, audienceIcon: <CreatorIcon />, channel: 'LinkedIn', audience: 'Professional Creators' },
        { channelIcon: <LinkedInIcon />, audienceIcon: <DataIcon />, channel: 'LinkedIn', audience: 'Enterprise and Data Centre Managers' }
    ];

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleItemClick = (item) => {
        setSelectedValue(item);
        setChannel(item.channel);
        setAudience(item.audience);
        onSelectChannel(item.channel);
        onSelectAudience(item.audience);
        setIsOpen(false);
    };

    return (
        <>
            <label>Select channel and audience</label>
            <div className="dropdown" ref={dropdownRef}>
                <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                    {selectedValue ?
                        <>
                            <span>{selectedValue.channelIcon}{selectedValue.channel}</span>
                            <span>{selectedValue.audienceIcon}{selectedValue.audience}</span>
                        </>
                        :
                        'Select from dropdown'
                    } <ArrowDownIcon className='ddl-icon' />
                </div>
                {isOpen && (
                    <div className="dropdown-list">
                        {dropdownItems.map((item, index) => (
                            <div className="dropdown-item" key={index} onClick={() => handleItemClick(item)}>
                                <span>{item.channelIcon}{item.channel}</span><span>{item.audienceIcon}{item.audience}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default ChannelAudienceDropdown;

import React from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import './image-carousel.scss';

const ImageCarousel = (props) => {
  const { contentList } = props;
  
  // Calculate start index based on the latest occurrence of "image-1" in contentList
  const startIndex = contentList.reduce((acc, item, index) => {
    if (item.id === 'image-1') {
      acc = index;
    }
    return acc;
  }, 0);

  // console.log("Sample", contentList);
  
  return (
    <div className='image-carousel'>
      <ImageGallery
        items={contentList}
        thumbnailPosition="left"
        showPlayButton={false}
        disableThumbnailScroll={false}
        showFullscreenButton={false}
        onSlide={props.handleSlide}
        showIndex={true}
        startIndex={startIndex} // Pass startIndex to the ImageGallery component
      />
    </div>
  );
};

export default ImageCarousel;

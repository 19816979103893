import React, { useEffect, useState } from 'react';
import AuthService from "./../../services/auth.service";
import robotImage from '../../images/robot.png';
import PercentageIndicator from './PercentageIndicator';
import scoreIndicator from '../../resources/images/score-indicator.png';
import './predictor.scss';

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import ImagePopup from '../ImagePopup';
import PieChart from './PieChart';

const Predictor = (props) => {
    const {
        handleRating,
        imageScore,
        textScore,
        textDescription,
        imageDescription,
        new_caption,
        generated_image,
        generatedImages,
        channel,
        audience,
        userId } = props;
    const [selectedTab, setSelectedTab] = useState("textTab");
    const [showImagePopup, setShowImagePopup] = useState(false);
    // console.log('link', generated_image);
    const [imageScoreList, setImageScoreList] = useState([]);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };

    useEffect(() => {
        if (generatedImages) {
            setImageScoreList(new Array(generatedImages.length).fill(0));
        }
    }, [generatedImages])

    const openImagePopup = (index) => {
        setActiveImageIndex(index);
        setShowImagePopup(true);
    };

    const closeImagePopup = () => {
        setShowImagePopup(false);
    };

    const revealScore = async (index) => {
        setIsLoading(true);
        console.log('generatedImages[index] - ', generatedImages[index], ' imageDescription - ', imageDescription, ' channel - ', channel, ' audience - ', audience, ' userId - ', userId);
        const generatedImageScore = await AuthService.getImageScore(generatedImages[index], new_caption, channel, audience, userId).then((imgScore) => {
            console.log(imgScore);
            const imageScores = [...imageScoreList];
            imageScores[activeImageIndex] = imgScore.imagescore;
            setImageScoreList([...imageScores]);
            setIsLoading(false);
        });
    }

    return <>
        <div className="insights-wrapper">
            <div className='score-predictor'>
                <div className='score-predictor__title'>
                    <span>Content Performance</span>
                </div>
                <div className='score-predictor__subtitle'>
                    <h3 className={`score-predictor__subHeading ${(selectedTab === 'textTab') && 'selected'}`} id="textTab" onClick={() => setSelectedTab('textTab')}>CAPTION</h3>
                    <h3 className={`score-predictor__subHeading image ${(selectedTab === 'imageTab') && 'selected'}`} id="imageTab" onClick={() => setSelectedTab('imageTab')}>IMAGE</h3>
                </div>
                {(selectedTab === 'imageTab' && imageScore) || (selectedTab === 'textTab' && textScore) ? (
                    <div className="score-wrapper">
                        <div className="score">
                            <PercentageIndicator percentage={selectedTab === 'imageTab' ? imageScore : textScore} />
                        </div>
                        <div className="score-summary">
                            <p className='score-summary-title score-indicator'><span>Score indicator</span></p>
                        </div>
                        <img src={scoreIndicator} className="scoring-scale" alt="scoring-scale" />
                    </div>
                ) : (
                    <div className='score-predictor__content'>
                        <div className='score-predictor__content-wrapper'>
                            <img src={robotImage} alt={'robot'} />
                            <h4 className="score-predictor__content-Heading">Evaluating Content Effectiveness and Providing Suggestions</h4>
                            <p className="score-predictor__content-description">Purpose of this tool is Assessing how well content performs and offering advice for improvement (Image and corresponding captions for demo Purpose)</p>
                        </div>
                    </div>
                )}
            </div>
            <div className='score-predictor recommendations'>
                <div className='score-predictor__title'>
                    <span>Recommendations</span>
                </div>
                <div className="score-wrapper">
                    <div className="score-summary">
                        <p className='score-summary-title'>{(selectedTab === 'textTab') ? 'Caption' : 'Image'} Analysis</p>
                        <p className='score-summary-description'>{(selectedTab === 'textTab') ? (textDescription || '--') : (imageDescription || '--')}</p>
                    </div>
                    {(selectedTab === 'textTab') && (
                        <div className="score-summary">
                            <p className='score-summary-title'>Caption Suggestions</p>
                            <p className='score-summary-description'>{new_caption || '--'}</p>
                        </div>
                    )}
                    {(selectedTab === 'imageTab') && (
                        <div className="score-summary">
                            <p className='score-summary-title'>AI Generated Image</p>
                            {/* <LightGallery
                                onInit={onInit}
                                speed={500}
                                plugins={[lgThumbnail, lgZoom]}
                            >
                                <img src={generated_image} alt='AI Generated Image' />
                            </LightGallery> */}
                            <div className='gen-img-container'>
                                {generatedImages && generatedImages.map((imgData, index) => {
                                    return <div className='image' key={`${imgData && imgData.substring(30, 40)}${index}`}>
                                        <img
                                            src={imgData}
                                            alt={imageDescription}
                                        />
                                        {imageScoreList[index] === 0 ?
                                            <button type="button" onClick={() => openImagePopup(index)}>View score</button>
                                            :
                                            <div className="img-score" onClick={() => openImagePopup(index)}>
                                                <PieChart percentage={imageScoreList[index]} size={80} />
                                            </div>
                                        }
                                    </div>
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

        {showImagePopup && <ImagePopup
            activeImageIndex={activeImageIndex}
            setActiveImageIndex={(index) => setActiveImageIndex(index)}
            images={generatedImages}
            onClose={closeImagePopup}
            revealScore={(index) => revealScore(index)}
            imageScoreList={imageScoreList}
            isLoading={isLoading}
            channel={channel}
            audience={audience}
        />
        }
    </>
};

export default Predictor;

// Footer.tsx

import React from "react"

import styles from "./Footer.module.css"

const Footer = () => {
  return (
    <div className={styles.footer}>
      <p>© Intel Campaign AI All Rights Reserved. | Feedback | T & C</p>
    </div>
  )
}

export default Footer
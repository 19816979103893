// import React, { useState, useRef } from 'react';
// import './content-upload.scss';
// import Loader from '../loader';
// import upload from '../../resources/images/upload.svg';

// const staticList = [{
//     id: 'image-1',
//     original: "",
//     thumbnail: "",
//     originalClass: "",
//     thumbnailClass: "",
//     description: "",
//     slideIndex: "",
//     loderIndex: "1"
// },{
//     id: 'image-2',
//     original: "",
//     thumbnail: "",
//     originalClass: "",
//     thumbnailClass: "",
//     description: "",
//     slideIndex: "",
//     loderIndex: "2"
// },
// {
//     id: 'image-3',
//     original: "",
//     thumbnail: "",
//     originalClass: "",
//     thumbnailClass: "",
//     description: "",
//     slideIndex: "",
//     loderIndex: "3"
// },
// ];

// const ContentUpload = (props) => {
//     const {addToList, contentList, currentImageIndex} = props;
//     const [isOverlayOpen, setOverlay] = useState(false);
//     const [image, setImage] = useState('');
//     const [isLoader, setLoader] = useState(false);
//     const [hasError, setError] = useState(false);
//     const imageList = useRef([{
//         id: 'image-1',
//         original: "",
//         thumbnail: "",
//         originalClass: "",
//         thumbnailClass: "",
//         description: "",
//         slideIndex: "",
//         loderIndex: "1",
//         fileName: ""
//     },{
//         id: 'image-2',
//         original: "",
//         thumbnail: "",
//         originalClass: "",
//         thumbnailClass: "",
//         description: "",
//         slideIndex: "",
//         loderIndex: "2",
//         fileName: ""
//     },
//     {
//         id: 'image-3',
//         original: "",
//         thumbnail: "",
//         originalClass: "",
//         thumbnailClass: "",
//         description: "",
//         slideIndex: "",
//         loderIndex: "3",
//         fileName: ""
//     },
//     ]);

//     const handleChange = (e, id) => {
//         const file = e.target.value?.split('\\');
//         const fileName = (file && file[file.length - 1]) || '';
//         const imageListCopy = [...imageList.current];

//         imageListCopy.forEach(image => {
//             if(id === image?.id) {
//                 image.original = image.thumbnail =  URL.createObjectURL(e.target.files[0]);
//                 let splitIndex = id.split("image-")
//                 image.slideIndex= parseInt(splitIndex[1]) + Object.keys(contentList).length;
//                 image.fileName = fileName;
//             }
//         })
//         imageList.current = [...imageListCopy];
//     }

//     const createCarousel = (e) => {
//         // setLoader(true);
//         const imageListCopy = [...imageList.current];
//         const addedImages = imageListCopy?.filter(image => {
//             return image.original && image.description;
//         });
//         if(addedImages.length > 0) {
//             setOverlay(false);
//             setError(false);
//             addToList([...contentList,...addedImages ]);
//             props.handleImageSlide(imageListCopy, addedImages.length)
//             imageList.current = [...staticList];
//         } else {
//             setError(true);
//         }
//     }
    
//     const handleTextArea = (e, id) => {
//         const imageListCopy = [...imageList.current];
//         imageListCopy.forEach(image => {
//             if(id === image?.id) {
//                 image.description = e.target.value;
//             }
//         });
//         imageList.current = [...imageListCopy];
//     }
//     // console.log(imageList.current);
//     const closeOverlay = () => {
//         setError(false);
//         setOverlay(false);
//         imageList.current = [...staticList];
//     }


//     return (
//         <div className='content-upload'>
//             <div className='file-upload-section'>
//                 {currentImageIndex !== '' && <p>{currentImageIndex !== '' && contentList[currentImageIndex]?.fileName}</p>}
//                 <button onClick={() => setOverlay(true)}><img src={upload}/><span>UPLOAD ASSETS</span></button>
//             </div>
//             {isLoader && <Loader/>}
//             {isOverlayOpen && <div className='overlay-wrapper'>
//                 <div className='overlay'>
//                     <div className="overlay-header">
//                         <h2>Upload Assets</h2>
//                         <span className='close-overlay' onClick={closeOverlay}>close</span>
//                     </div>
//                     <div className='overlay-content-wrapper'>
//                         {hasError && <p className='error-message'>**Atleast one asset is required</p>}
//                         <div className="overlay-content">
//                             {imageList?.current?.map ((image, key) => {
//                                 const { id } = image;
//                                 return (
//                                     <div className='asset-wrapper' key={id}>
//                                         <h3 className='asset-heading'>Asset {key + 1}</h3>
//                                         <label>Caption:*</label>
//                                         <textarea name="postContent" rows={4} onBlur={(e) => handleTextArea(e, id)} />
//                                         <div>
//                                             <label>Image:*</label>
//                                             <input type="file" onChange={(e) => handleChange(e, id)} />
//                                             {/* {image && <img src={image} alt="file"/>} */}
//                                         </div>
//                                     </div>
//                                 );
//                             })}

//                         </div>
//                         <div className="button-wrapper">
//                             <button onClick={createCarousel}>Upload Asset</button>
//                         </div>
//                     </div>
//                 </div></div> }
//         </div>
//     );
// }

// export default ContentUpload;


import React, { useState, useRef } from 'react';
import './content-upload.scss';
import Loader from '../loader';
import upload from '../../resources/images/upload.svg';

const staticList = [{
    id: 'image-1',
    original: "",
    thumbnail: "",
    originalClass: "",
    thumbnailClass: "",
    description: "",
    slideIndex: "",
    loderIndex: "1",
    fileName: ""
}, {
    id: 'image-2',
    original: "",
    thumbnail: "",
    originalClass: "",
    thumbnailClass: "",
    description: "",
    slideIndex: "",
    loderIndex: "2",
    fileName: ""
},
{
    id: 'image-3',
    original: "",
    thumbnail: "",
    originalClass: "",
    thumbnailClass: "",
    description: "",
    slideIndex: "",
    loderIndex: "3",
    fileName: ""
},
];

const ContentUpload = (props) => {
    const { addToList, contentList, currentImageIndex } = props;
    const [isOverlayOpen, setOverlay] = useState(false);
    const [image, setImage] = useState('');
    const [isLoader, setLoader] = useState(false);
    const [hasError, setError] = useState(false);
    const imageList = useRef([{
        id: 'image-1',
        original: "",
        thumbnail: "",
        originalClass: "",
        thumbnailClass: "",
        description: "",
        slideIndex: "",
        loderIndex: "1",
        fileName: ""
    }, {
        id: 'image-2',
        original: "",
        thumbnail: "",
        originalClass: "",
        thumbnailClass: "",
        description: "",
        slideIndex: "",
        loderIndex: "2",
        fileName: ""
    },
    {
        id: 'image-3',
        original: "",
        thumbnail: "",
        originalClass: "",
        thumbnailClass: "",
        description: "",
        slideIndex: "",
        loderIndex: "3",
        fileName: ""
    },
    ]);

    const handleChange = (e, id) => {
        const file = e.target.value?.split('\\');
        const fileName = (file && file[file.length - 1]) || '';
        const uploadedFile = e.target.files[0];

        // Activate loader with a slight delay
        setTimeout(() => {
            setLoader(true);
        }, 50); // Adjust the delay as needed

        const newImageList = imageList.current.map(image => {
            if (id === image.id) {
                return {
                    ...image,
                    original: URL.createObjectURL(uploadedFile),
                    thumbnail: URL.createObjectURL(uploadedFile),
                    slideIndex: parseInt(id.split("image-")[1]) + Object.keys(contentList).length,
                    fileName: fileName
                };
            }
            return image;
        });

        imageList.current = newImageList;

        // Deactivate loader once upload process is complete
        setTimeout(() => {
            setLoader(false);
        }, 1000); // Adjust the delay as needed
    }

    const createCarousel = (e) => {
        // setLoader(true);
        const imageListCopy = [...imageList.current];
        const addedImages = imageListCopy?.filter(image => {
            return image.original && image.description;
        });
        if (addedImages.length > 0) {
            setOverlay(false);
            setError(false);
            addToList([...contentList, ...addedImages]);
            props.handleImageSlide(imageListCopy, addedImages.length)
            imageList.current = [...staticList];
        } else {
            setError(true);
        }
    }

    const handleTextArea = (e, id) => {
        const imageListCopy = [...imageList.current];
        imageListCopy.forEach(image => {
            if (id === image?.id) {
                image.description = e.target.value;
            }
        });
        imageList.current = [...imageListCopy];
    }
    // console.log(imageList.current);
    const closeOverlay = () => {
        setError(false);
        setOverlay(false);
        imageList.current = [...staticList];
    }


    return (
        <div className='content-upload'>
            <div className='file-upload-section'>
                {/* {currentImageIndex !== '' && <p>{currentImageIndex !== '' && contentList[currentImageIndex]?.fileName}</p>} */}
                <button onClick={() => setOverlay(true)}><img src={upload} /><span>UPLOAD ASSETS</span></button>
            </div>
            {isLoader && <Loader />}
            {isOverlayOpen && <div className='overlay-wrapper'>
                <div className='overlay'>
                    <div className="overlay-header">
                        <h2>Upload Assets</h2>
                        <span className='close-overlay' onClick={closeOverlay}>close</span>
                    </div>
                    <div className='overlay-content-wrapper'>
                        {hasError && <p className='error-message'>**Atleast one asset is required</p>}
                        <div className="overlay-content">
                            {imageList?.current?.map((image, key) => {
                                const { id } = image;
                                return (
                                    <div className='asset-wrapper' key={id}>
                                        <h3 className='asset-heading'>Asset {key + 1}</h3>
                                        <label>Caption:*</label>
                                        <textarea name="postContent" rows={4} onBlur={(e) => handleTextArea(e, id)} />
                                        <div>
                                            <label>Image:*</label>
                                            <input type="file" onChange={(e) => handleChange(e, id)} />
                                            {/* {image && <img src={image} alt="file"/>} */}
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                        <div className="button-wrapper">
                            <button onClick={createCarousel}>Upload Asset</button>
                        </div>
                    </div>
                </div></div>}
        </div>
    );
}

export default ContentUpload;












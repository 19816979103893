import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";
// import slider4 from "../images/intel-slider4.jpg"
import AuthService from "../services/auth.service";
import './home.scss';
import Predictor from "./Predictor";
import Header from './Header';
import ContentUpload from "./ContentUpload";
import DefaultImageScreen from "./DefaultImageScreen";
import Loader from "./loader";
import Campaign from "./Campaign";
import ChannelAudienceDropdown from "./ChannelAudienceDropdown";

const Home = () => {

  const form = useRef();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [imageScore, setImageScore] = useState("");
  const [textScore, setTextScore] = useState("");
  const [textDescription, setTextDescription] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [textRecommendation, setTextRecommendation] = useState("-");
  const [selectedImageUrl, setSelectedImageUrl] = useState("-");
  const [selectedImageText, setSelectedImageText] = useState("-");
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [contentList, setImageList] = useState([]);
  const [imageSlideData, setSlides] = useState("");
  const [imageScoreData, setScore] = useState([]);
  const [new_caption, setNewCaption] = useState("");
  const [generated_image, setGenerated_image] = useState("");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState("");
  const [selectedChannel, setSelectedChannel] = useState(""); // New state for selected channel
  const [selectedAudience, setSelectedAudience] = useState(""); // New state for selected audience

  useEffect(() => {
    document.body.classList.add('home');
    document.body.classList.remove('login');
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      setCurrentUser(currentUser);
      navigate("/home");
    }

  }, []);
  const payload = new FormData()

  async function handleImageSlide(datas, itemCount, channel, audience) {
    setLoader(true);
    for (const data of datas) {
      if (data.original != "") {
        let imageSrc = data.original
        let imageDescription = data.description
        // let channel = "Instagram"
        // let audience = "Tech Enthusiasts"
        let channel = selectedChannel
        let audience = selectedAudience

        const imageBase64 = await fetch(imageSrc)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((res) => {
              reader.onloadend = () => {
                res(reader.result);
              }
            })
          })

        if (currentUser) {
          let slideId = (data.slideIndex)
          let loaderId = (data.loderIndex)
          const imagegptResponse = await AuthService.getImageScore(imageBase64, imageDescription, channel, audience, currentUser.id).then((value) => {
            if (loaderId == itemCount) {
              setLoader(false);
              handleSlide(contentList.length);
            }
            return { "imageId": slideId, "imageScore": value.imagescore, "imageDescription": value.imagedescription, "textScore": value.textscore, "textDescription": value.textdescription, "new_caption": value.new_caption, "generated_image": value.generated_image, generatedImages: value.generated_images }
          });
          console.log("Eample", imagegptResponse);

          if (slideId === (contentList.length + 1)) {
            setPerformaceRecommendations(imagegptResponse.imageScore, imagegptResponse.textDescription, imagegptResponse.imageDescription, imagegptResponse.textScore, imagegptResponse.new_caption, imagegptResponse.generated_image, imagegptResponse.generatedImages);
          }
          imageScoreData[slideId - 1] = imagegptResponse;
        }
      }
    }
  }

  function handleSlide(imageId) {
    setCurrentImageIndex(imageId);
    setPerformaceRecommendations(imageScoreData[imageId] ? imageScoreData[imageId].imageScore : 0,
      imageScoreData[imageId] ? imageScoreData[imageId].textDescription : 0,
      imageScoreData[imageId] ? imageScoreData[imageId].imageDescription : 0,
      imageScoreData[imageId] ? imageScoreData[imageId].textScore : 0,
      imageScoreData[imageId] ? imageScoreData[imageId].new_caption : 0,
      imageScoreData[imageId] ? imageScoreData[imageId].generated_image : 0,
      imageScoreData[imageId] ? imageScoreData[imageId].generatedImages : 0,);
  }

  const setPerformaceRecommendations = (imageScore, textDescription, imageDescription, textScore, newCaption, generatedImage, generatedImages) => {
    setImageScore(imageScore);
    setTextDescription(textDescription);
    setImageDescription(imageDescription);
    setTextScore(textScore);
    setNewCaption(newCaption);
    setGenerated_image(generatedImage);
    setGeneratedImages(generatedImages)
  }

  return (
    <>
      <Header />
      {isLoader && <Loader />}
      <div className={"home-page"}>
        <div className="image-section">
          {contentList?.length > 0 ?
            <ImageCarousel handleImageSlide={handleImageSlide} handleSlide={handleSlide} contentList={contentList} /> : <DefaultImageScreen />}
          {/* <Campaign/> */}
          {/* <Campaign
            onSelectChannel={setSelectedChannel}
            onSelectAudience={setSelectedAudience}
            onApplyFilters={() => handleImageSlide(selectedChannel, selectedAudience)}
          /> */}
          <ChannelAudienceDropdown
            onSelectChannel={(channel) => setSelectedChannel(channel)}
            onSelectAudience={(audience) => setSelectedAudience(audience)}
          />
          {selectedChannel && selectedAudience && <ContentUpload addToList={(list) => { setImageList(list) }} handleImageSlide={handleImageSlide} contentList={contentList} currentImageIndex={currentImageIndex} />}
        </div>
        <Predictor
          handleRating={handleImageSlide}
          imageScore={imageScore}
          textDescription={textDescription}
          imageDescription={imageDescription}
          textScore={textScore}
          new_caption={new_caption}
          generated_image={generated_image}
          generatedImages={generatedImages}
          channel={selectedChannel}
          audience={selectedAudience}
          userId={currentUser && currentUser.id} />
      </div>
    </>
  );
};

export default Home;
// LoginContainer.jsx
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import styles from "./LoginContainer.module.css"; // Import the external CSS file
import img from "../../images/intel-small-logo.png";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";
import {
    Paper,
    styled,
  } from "@mui/material";

// import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    borderRadius: theme.spacing(0),
    color: theme.palette.text.secondary,
  }));

const LoginContainer = () => {

    const form = useRef();
    const checkBtn = useRef();
  
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
  
    const navigate = useNavigate();
    const payload = new FormData()
  
    const onChangeUsername = (e) => {
      const username = e.target.value;
      setUsername(username);
    };
  
    const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
    };
  
    const handleLogin = (e) => {
      e.preventDefault();
  
      setMessage("");
      setLoading(true);
  
      form.current.validateAll();
  
      if (checkBtn.current.context._errors.length === 0) {
        payload.append("username",username);
        payload.append("password",password);
  
        AuthService.login(payload).then(
          (response) => {

            if(response.access_token){
                navigate("/home");                
            } else {
                const resMessage = response.error ||
                    response.toString();
                setMessage(resMessage);
            }          
  
            setLoading(false);
            // window.location.reload();
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.error) ||
              error.error ||
              error.toString();
  
            setLoading(false);
            setMessage(resMessage);
          }
        );
      } else {
        setLoading(false);
      }
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMe = () => {
        setRememberMe(!rememberMe);
    };
    

    return (
        <div className={styles.loginContainer}>
            <div className={styles.logocontainer}>
                <img src={img} alt="Small Logo" className="logo" width={"20px"} height={"20px"} />
                <p className={styles.companyname}>Intel Campaign AI</p>
            </div>

            <h2 className={styles.logintitle}>Login</h2>

            <p className={styles.logindescription}>Login with your registered email credentials for Intel</p>

            {/* <button className={styles.microsoftbtn}>
                <img src={microsoftimg} alt="microsoft" width={"20px"} height={"20px"} />
                sign in with microsoft
            </button> */}

            {/* <Link to="/pages/layout/Layout" className={styles.microsoftbtn}>
                <img src={microsoftimg} alt="microsoft" width={"20px"} height={"20px"} />
                sign in with microsoft
            </Link> */}
            <Form onSubmit={handleLogin} ref={form}>

                <div className={styles.inputcontainer}>
                    <label>EMAIL</label>
                    <input type="email" placeholder="Enter your email address" defaultValue={username}
                    onChange={e => { setUsername(e.target.value) }} />
                </div>

                <div className={styles.inputcontainer}>
                    <label>PASSWORD</label>
                    <div className={styles.passwordinputcontainer}>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            defaultValue={password}
                            onChange={e => { setPassword(e.target.value) }}
                        />
                        <span onClick={handleTogglePassword} className={styles.passwordtoggle}>
                            {showPassword ? "Hide" : "Show"}
                        </span>
                    </div>
                </div>

                <div className={styles.checkboxcontainer}>
                    <label>
                        <input type="checkbox" checked={rememberMe} onChange={handleRememberMe} className={styles.remember} /> Remember me
                    </label>
                    <span className={styles.forgotpassword}>Forgot Password?</span>
                </div>

                <div className="form-group">
                    <button className={styles.loginbtn} onClick={handleLogin} disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Login
                    </button>
                </div>

                {message && (
                    <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                    </div>
                )}
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
            <div className={styles.formlink}>
                <span>Don't have an account? <Link to='/signup'>Signup</Link></span>
            </div>
        </div>
    );
};

export default LoginContainer;




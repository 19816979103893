import axios from "axios";

let API_URL = process.env.REACT_APP_LOCAL_BACKEND_ENDPOINT_URL;
if(process.env.REACT_APP_SET_MODE == "prod"){
     API_URL = process.env.REACT_APP_PROD_BACKEND_ENDPOINT_URL;
} 

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const getImageRecommendation = (imagePath, imageDescription, userId) => {
  return axios.post(API_URL + "/imageValidation", {
    imagePath,
    imageDescription,
    userId,
  }).then((response) => {
      return response.data;
  });;
};

const getImageScore = (imagePath, imageDescription, channel, audience, userId) => {
  const axiosInstance = axios.create({
    timeout: 200000, // for this we will put some logic based on the image base64 size...
  });
  
  return axiosInstance.post(API_URL + "/imageTextScore", {
    imagePath,
    imageDescription,
    channel,
    audience,
    userId,
  }).then((response) => {
      return response.data;
  });;
};

const getTextScore = (imagePath, imageDescription, userId) => {
  return axios.post(API_URL + "/textScore", {
    imagePath,
    imageDescription,
    userId,
  }).then((response) => {
      return response.data;
  });;
};

const login = (payload) => {
  return axios
    .post(API_URL + "/login", payload)
    .then((response) => {
      if (response.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getImageRecommendation,
  getImageScore,
  getTextScore,
}

export default AuthService;

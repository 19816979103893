import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginContainer from "./LoginContainer/LoginContainer";
import styles from "./LoginContainer/LoginPage.module.css";
import contentimg from "../images/image 17.png";
import logo from "../images/intel-logo-png.png";
import Footer from "./Footer/Footer";
import adminimg from "../images/admin.png";
import userimg from "../images/user.png";

import AuthService from "../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add('login');
    document.body.classList.remove('home');
    const currentUser = AuthService.getCurrentUser();

    // if (currentUser) {
    //   setCurrentUser(currentUser);
    //   navigate("/home"); 
    // }

  }, []);

  return (
    <div className={styles.container}>
        <div className={styles.image}>
            <img src={contentimg} alt="image" width={"100%"} height={"520px"} />
            <div className={styles.overlaycontainer}>
                <img src={logo} alt="intel imag" width={"207px"} height={"127px"}/>
                <h1 className={styles.overlaytext}>
                    Welcome to Intel Campaign AI, <br /> Your AI Research Chatbot
                </h1>
                <p className={styles.overlaytext__para}>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. <br /> Natus architecto error quaerat fuga corrupti itaque repellat <br />{" "}
                    possimus veritatis, quas sequi.
                </p>
            </div>
        </div>
        <div className={styles.logincontainer}>
            <h1>Login as</h1>
            <div className={styles.boxcontainer}>
                <div className={styles.box}>
                    <img src={adminimg} alt="Image 1" width={"40px"} height={"40px"} />
                    <p>Admin</p>
                </div>
                <div className={styles.box2}>
                    <img src={userimg} alt="Image 2" />
                    <p>ORG User</p>
                </div>
            </div>
        </div>
        <LoginContainer />
        <Footer />
    </div>
  );
};

export default Login;
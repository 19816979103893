// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-image-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #e4e4e4;
  padding: 30px 0;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/DefaultImageScreen/default-image-screen.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBCCY;EDAZ,yBAAA;EACA,eAAA;EACA,mBAAA;AADJ","sourcesContent":["@import '../../styles/variables.scss';\n\n.default-image-screen {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: $primary-white;\n    border: 1px solid #e4e4e4;\n    padding: 30px 0;\n    margin-bottom: 30px;\n}","$Tahoma-Regular: 'Tahoma-Regular';\n$Tahoma-Bold: 'Tahoma-Bold';\n\n\n\n\n$primary-black: #262626;\n$primary-white: white;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import './header.scss';
import intelLogo from '../../resources/images/intel.svg';
import profile from '../../resources/images/profile.svg';
import language from '../../resources/images/language.svg';
import searchIcon from '../../resources/images/search.png';



const Header = () => {
    return <header className='header'>
        <div className="logo">
            <img src={intelLogo} alt="intel-logo"/>
            <h1>Content Performance Copilot</h1>
        </div>
        <div className="profile-wrapper">
            <img src={profile} className='profile-icon' alt="profile-icon"/>
            <img src={language} className='language-selector' alt="language-selector"/>
            {/* <div className="search-wrapper">
                <img src={searchIcon} className='search-icon' alt="search"/>
                <input type="search" className='search'/>
            </div> */}
        </div>
    </header>
}

export default Header;
import React, { useState } from 'react';
import './image-popup.scss';
import PercentageIndicator from "./../Predictor/PercentageIndicator";
import Loader from "./../loader";
import scoreImg from "./../../resources/images/score-image.png";
import closeImg from "./../../resources/images/close-img.png"

const ImagePopup = ({ activeImageIndex, setActiveImageIndex, images, onClose, revealScore, imageScoreList, isLoading, channel, audience }) => {
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const handleImageClick = (image, index) => {
        setSelectedImage(image);
        setActiveImageIndex(index);
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-close" onClick={onClose}>
                        <img src={closeImg} alt="close-image-popup-img" />
                    </div>
                    <div className="modal-columns">
                        <div className="modal-left-column">
                            <div className="selected-img">
                                <img src={selectedImage} alt="Main" className="main-image" />
                            </div>
                            <div className="indicator">
                                <p>Image {`${activeImageIndex + 1}/${images.length}`}</p>
                            </div>
                            <div className="image-list">
                                {images.map((image, index) => (
                                    <div className={`thumbnail-container ${index === activeImageIndex && 'active'}`}>
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Thumbnail ${index}`}
                                            className="thumbnail"
                                            onClick={() => handleImageClick(image, index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-right-column">
                            <div className="image-info-container">
                                <h2>AI Generated Image Recommendation</h2>
                                <p>For {channel} this makes an excellent choice to capture the attention of the {audience} audience.</p>
                                {imageScoreList[activeImageIndex] === 0 ?
                                    <button className="reveal-score" type="button" onClick={() => revealScore(activeImageIndex)}>
                                        <img src={scoreImg} alt="score-image" />
                                        <span>Reveal score</span>
                                    </button>
                                    :
                                    <div className="score">
                                        <PercentageIndicator percentage={imageScoreList[activeImageIndex]} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImagePopup;

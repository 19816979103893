// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */

.Footer_footer__kXlYa {
    text-align: center;
    /* padding: 10px; */
    background-color: #f6f5f4;
    position: fixed;
    bottom: 0;
    width: 100%;
}
.Footer_footercontent__nqk8E {
    justify-content: space-evenly;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,SAAS;IACT,WAAW;AACf;AACA;IACI,6BAA6B;AACjC","sourcesContent":["/* Footer.css */\n\n.footer {\n    text-align: center;\n    /* padding: 10px; */\n    background-color: #f6f5f4;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n}\n.footercontent {\n    justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__kXlYa`,
	"footercontent": `Footer_footercontent__nqk8E`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import defaultImageScreen from "../../resources/images/default-image-screen.png";
import './default-image-screen.scss';


const DefaultImageScreen = () => {
    return (
      <div className="default-image-screen">
        <img src={defaultImageScreen}/>
      </div>
    )
  }

export default DefaultImageScreen;
import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";

const App = () => {

  useEffect(() => {
    // 👇️ adding multiple classes to the body element
    document.body.classList.add(
      'bg-intel'
    );

    // 👇️ checking if the body element contains a class
    if (document.body.classList.contains('bg-salmon')) {
      console.log('body tag contains class');
    }
  }, []);

  return (
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path={"/home"} element={<Home />} />
        <Route exact path="/register" element={<Register />} />
      </Routes>
  );
};

export default App;

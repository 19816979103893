// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #e4e4e4;
}
.header .logo {
  display: flex;
  align-items: center;
}
.header .logo img {
  height: 50px;
  width: 50px;
}
.header .logo h1 {
  margin-left: 40px;
  color: #0068B5;
  font-size: 16px;
}
.header .profile-wrapper {
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-right: 30px;
}
.header .profile-wrapper img {
  margin-right: 15px;
  height: 15px;
  width: 15px;
}
.header .search-wrapper {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  height: 100%;
  position: relative;
}
.header .search-wrapper .search {
  border: none;
  text-decoration: none;
  padding-left: 40px;
}
.header .search-wrapper .search-icon {
  position: absolute;
  left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;AADJ;AAEI;EACI,aAAA;EACA,mBAAA;AAAR;AACQ;EACI,YAAA;EACA,WAAA;AACZ;AACQ;EACI,iBAAA;EACA,cAAA;EACA,eAAA;AACZ;AAEI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AACQ;EACI,kBAAA;EACA,YAAA;EACA,WAAA;AACZ;AAEI;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AAAR;AACQ;EACI,YAAA;EACA,qBAAA;EACA,kBAAA;AACZ;AAAY;EACI,kBAAA;EACA,UAAA;AAEhB","sourcesContent":["@import '../../styles/variables.scss';\n\n.header {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: $primary-white;\n    border-bottom: 1px solid #e4e4e4;\n    .logo {\n        display: flex;\n        align-items: center;\n        img {\n            height: 50px;\n            width: 50px;\n        }\n        h1 {\n            margin-left: 40px;\n            color: #0068B5;\n            font-size: 16px;\n        }\n    }\n    .profile-wrapper {\n        display: flex;\n        align-self: stretch;\n        align-items: center;\n        margin-right: 30px;\n        img {\n            margin-right: 15px;\n            height: 15px;\n            width: 15px;\n        }\n    }\n    .search-wrapper {\n        display: flex;\n        align-items: center;\n        background-color: #f7f7f7;\n        height: 100%;\n        position: relative;\n        .search {\n            border: none;\n            text-decoration: none;\n            padding-left: 40px;\n            &-icon {\n                position: absolute;\n                left: 10px;\n            }\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

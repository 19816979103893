// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 30px 0 30px;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .home-page {
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1500px;
    margin: 0 auto;
  }
}
.home-page .image-section {
  margin: 0 30px;
}
@media only screen and (min-width: 768px) {
  .home-page .image-section {
    flex-basis: 40%;
    margin-bottom: 0;
    padding-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/home.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;EACA,sBAAA;EACA,oBAAA;EACA,sBAAA;AACJ;AAAI;EANJ;IAOQ,mBAAA;IACA,kBAAA;IACA,mBAAA;IACA,iBAAA;IACA,cAAA;EAGN;AACF;AADI;EACI,cAAA;AAGR;AAFQ;EAFJ;IAGQ,eAAA;IACA,gBAAA;IACA,gBAAA;EAKV;AACF","sourcesContent":[".home-page {\n    display: flex;\n    align-items: stretch;\n    flex-direction: column;\n    padding: 30px 0 30px;\n    box-sizing: border-box;\n    @media only screen and (min-width: 768px) {\n        flex-direction: row;\n        padding-left: 15px;\n        padding-right: 15px;\n        max-width: 1500px;\n        margin: 0 auto;\n    }\n\n    .image-section {\n        margin: 0 30px;\n        @media only screen and (min-width: 768px) {\n            flex-basis: 40%;\n            margin-bottom: 0;\n            padding-right: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

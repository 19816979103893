import React, { useState } from 'react';
import './campaign.scss';
import instagram from '../../resources/images/instagram.png';
import google from '../../resources/images/google.png';
import linkedIn from '../../resources/images/linkedIn.png';
import amazon from '../../resources/images/amazon.png';
import arrow from '../../resources/images/arrow.svg';

const campaignData = [
    {
        src: instagram,
        title: 'Instagram'
    },
    {
        src: linkedIn,
        title: 'LinkedIn'
    },
    {
        src: amazon,
        title: 'Amazon'
    },
    {
        src: google,
        title: 'GoogleAds'
    }
];

const audience = [
    {
        src: '',
        title: 'Tech Enthusiasts'
    },
    {
        src: '',
        title: 'Gamers'
    },
    {
        src: '',
        title: 'Professional Creators'
    },
    {
        src: '',
        title: 'Enterprise and Data Center Managers'
    },
]


const Dropdown = (props) => {
    const { title, list, placeholder, onSelectItem } = props;
    const [selectedItem, setSelected] = useState(list[0]);
    const [isOpen, setDropdownState] = useState(false);

    const dropdownHandler = () => {
        setDropdownState(!isOpen);
    };

    const setSelectedItem = (item) => {
        setSelected(item);
        onSelectItem(item);
        setDropdownState(false);
    };

    return (
        <div className='dropdown-wrapper'>
            <span className='dropdown-title'>{title}</span>
            <div className='dropdown'>
                <div className='selected' onClick={dropdownHandler}>
                    {selectedItem?.title ? <div className="selected-item">
                        {(selectedItem?.src && <img src={selectedItem.src} alt={selectedItem?.title} />)}
                        <span>{selectedItem.title}</span>
                    </div> : <span>{placeholder}</span>}
                    <img src={arrow} className={`${isOpen ? 'open' : ''} arrow`} alt={'arrow'} /></div>
                {isOpen && <ul className="dropdown-list">
                    {list?.map(item => {
                        return <li onClick={() => setSelectedItem(item)}>{item?.src && <img src={item.src} />} <span>{item?.title}</span></li>
                    })}
                </ul>}
            </div>
        </div>

    )
}


const Campaign = (props) => {
    const { onSelectChannel, onSelectAudience, onApplyFilters } = props;
    return (
        <>
            <div className='campaign-component-wrapper'>
                <div className='campaign-component'>
                    <Dropdown title={"Channel"} list={campaignData} placeholder={"Select eg.Instagram"} onSelectItem={onSelectChannel} />
                    <Dropdown title={"Audience"} list={audience} placeholder={"Select Audience type"} onSelectItem={onSelectAudience} />
                    {/* <button>Apply</button> */}
                </div>
                <p className='description'>Choose the filters from the dropdown and upload your assets by clicking the "UPLOAD ASSETS" button.</p>
            </div>
        </>

    )
}
export default Campaign;
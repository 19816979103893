import React, { useEffect } from "react";
import './loader.scss'

const Loader = () => {
    return (
        <>
            <div className="loader-overlay"></div>
            <div className="loader">
                <div className="roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    )
}

export default Loader;